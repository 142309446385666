const NAME = 'LIVECHAT';
const CNameMapping = {
  [NAME]: NAME,
  LiveChat: NAME,
  Live_Chat: NAME,
  livechat: NAME,
  'LIVE CHAT': NAME,
  'Live Chat': NAME,
  'live chat': NAME,
};

export { NAME, CNameMapping };
