const NAME = 'PROFITWELL';
const CNameMapping = {
  [NAME]: NAME,
  ProfitWell: NAME,
  profitwell: NAME,
  Profitwell: NAME,
  'Profit Well': NAME,
  'profit well': NAME,
  'Profit well': NAME,
};

export { NAME, CNameMapping };
