const NAME = 'ROCKERBOX';
const CNameMapping = {
  [NAME]: NAME,
  Rockerbox: NAME,
  rockerbox: NAME,
  RockerBox: NAME,
  'Rocker box': NAME,
  'rocker box': NAME,
  'Rocker Box': NAME,
};

export { NAME, CNameMapping };
