const NAME = 'GTM';
const CNameMapping = {
  [NAME]: NAME,
  'Google Tag Manager': NAME,
  'google tag manager': NAME,
  'googletag manager': NAME,
  googletagmanager: NAME,
};

export { NAME, CNameMapping };
