const NAME = 'TVSQUARED';
const CNameMapping = {
  [NAME]: NAME,
  TVSquared: NAME,
  tvsquared: NAME,
  tvSquared: NAME,
  TvSquared: NAME,
  Tvsquared: NAME,
  'TV Squared': NAME,
  'tv squared': NAME,
  'tv Squared': NAME,
};

export { NAME, CNameMapping };
