const NAME = 'ROLLBAR';
const CNameMapping = {
  [NAME]: NAME,
  RollBar: NAME,
  Roll_Bar: NAME,
  rollbar: NAME,
  Rollbar: NAME,
  'ROLL BAR': NAME,
  'Roll Bar': NAME,
  'roll bar': NAME,
};

export { NAME, CNameMapping };
