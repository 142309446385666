const NAME = 'REDDIT_PIXEL';
const CNameMapping = {
  [NAME]: NAME,
  Reddit_Pixel: NAME,
  RedditPixel: NAME,
  REDDITPIXEL: NAME,
  redditpixel: NAME,
  'Reddit Pixel': NAME,
  'REDDIT PIXEL': NAME,
  'reddit pixel': NAME,
};

export { NAME, CNameMapping };
