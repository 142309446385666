const NAME = 'VWO';
const CNameMapping = {
  [NAME]: NAME,
  VisualWebsiteOptimizer: NAME,
  Visualwebsiteoptimizer: NAME,
  visualwebsiteoptimizer: NAME,
  vwo: NAME,
  'Visual Website Optimizer': NAME,
  'Visual website optimizer': NAME,
  'visual website optimizer': NAME,
};

export { NAME, CNameMapping };
