const NAME = 'LAUNCHDARKLY';
const CNameMapping = {
  [NAME]: NAME,
  LaunchDarkly: NAME,
  Launch_Darkly: NAME,
  'Launch Darkly': NAME,
  launchDarkly: NAME,
  'launch darkly': NAME,
};

export { NAME, CNameMapping };
