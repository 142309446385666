const NAME = 'QUANTUMMETRIC';
const CNameMapping = {
  [NAME]: NAME,
  'Quantum Metric': NAME,
  'quantum Metric': NAME,
  'quantum metric': NAME,
  QuantumMetric: NAME,
  quantumMetric: NAME,
  quantummetric: NAME,
  Quantum_Metric: NAME,
};

export { NAME, CNameMapping };
