const NAME = 'CONVERTFLOW';
const CNameMapping = {
  [NAME]: NAME,
  Convertflow: NAME,
  convertflow: NAME,
  convertFlow: NAME,
  ConvertFlow: NAME,
  'Convert flow': NAME,
  'convert flow': NAME,
  'convert Flow': NAME,
  'Convert Flow': NAME,
  'CONVERT FLOW': NAME,
};

export { NAME, CNameMapping };
