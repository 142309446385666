const NAME = 'MP';
const CNameMapping = {
  [NAME]: NAME,
  MIXPANEL: NAME,
  Mixpanel: NAME,
  'MIX PANEL': NAME,
  'Mix panel': NAME,
  'Mix Panel': NAME,
};

export { NAME, CNameMapping };
