const NAME = 'COMSCORE';
const CNameMapping = {
  [NAME]: NAME,
  Comscore: NAME,
  'Com Score': NAME,
  'com Score': NAME,
  'com score': NAME,
  'Com score': NAME,
};

export { NAME, CNameMapping };
