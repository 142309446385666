const NAME = 'GOOGLEADS';
const CNameMapping = {
  [NAME]: NAME,
  'Google Ads': NAME,
  GoogleAds: NAME,
  'GOOGLE ADS': NAME,
  'google ads': NAME,
  googleads: NAME,
};

export { NAME, CNameMapping };
