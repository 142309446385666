const NAME = 'GOOGLE_OPTIMIZE';
const CNameMapping = {
  [NAME]: NAME,
  'Google Optimize': NAME,
  GoogleOptimize: NAME,
  Googleoptimize: NAME,
  GOOGLEOPTIMIZE: NAME,
  'google optimize': NAME,
  'Google optimize': NAME,
  'GOOGLE OPTIMIZE': NAME,
};

export { NAME, CNameMapping };
