const NAME = 'MICROSOFT_CLARITY';
const CNameMapping = {
  [NAME]: NAME,
  'Microsoft Clarity': NAME,
  'Microsoft clarity': NAME,
  'microsoft clarity': NAME,
  Microsoft_clarity: NAME,
  MicrosoftClarity: NAME,
  MICROSOFTCLARITY: NAME,
  microsoftclarity: NAME,
  microsoftClarity: NAME,
};

export { NAME, CNameMapping };
