const NAME = 'GA';
const CNameMapping = {
  [NAME]: NAME,
  'Google Analytics': NAME,
  GoogleAnalytics: NAME,
  'GOOGLE ANALYTICS': NAME,
  'google analytics': NAME,
};

export { NAME, CNameMapping };
